// config for categories, used e.g. in CoursesBox
export const collaborators = {
  SCHOOL: {
    label: 'Školy',
    labelTitle: 'škol',
  },
  LECTURER: {
    label: 'Lektoři',
    labelTitle: 'lektorů',
  },
  INSTITUTION: {
    label: 'Spoluprac. instituce',
    labelTitle: 'spoluprac. institucí',
  },
}

export const getCollaboratorsKeys = () => Object.keys(collaborators)
