/* eslint-disable react/no-danger */
import Select from 'react-select'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { compose, withProps } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'

import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import ListingCourses from './ListingCourses'
import { Line, RH } from '.'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'

const icons = {
  SCHOOL: require('../data/images/icons/map-school.png'),
  LECTURER: require('../data/images/icons/map-lecturer.png'),
  INSTITUTION: require('../data/images/icons/map-institution.png'),
}

class Map extends React.Component {
  state = {
    markerLabelVisible: false,
    activeMarkerId: null,
  }

  ref = null

  handleMarkerClick = (e, activeMarkerId) => {
    if (e.latLng) {
      this.setState({ activeMarkerId })
    }
  }

  handleMapMove = () => {
    const { itemsToFilter, onFilteredItemsChange } = this.props
    const map = this.ref

    const bounds = map.getBounds()

    const items = itemsToFilter.filter(item => bounds.contains(item.location))

    onFilteredItemsChange(items)
  }

  render() {
    const { items, markerLocation, zoom } = this.props
    const { markerLabelVisible, activeMarkerId } = this.state

    return (
      <GoogleMap
        ref={ref => {
          this.props.onRefUpdate(ref)
          this.ref = ref
        }}
        zoom={zoom}
        defaultCenter={{ lat: 49.65362053836695, lng: 15.42044259687501 }}
        onCenterChanged={this.handleMapMove}
        onBoundsChanged={this.handleMapMove}
        onTilesLoaded={this.handleMapMove}
      >
        {markerLocation && markerLocation.lat && (
          <Marker
            position={markerLocation}
            onClick={e => {
              this.setState({
                markerLabelVisible: !markerLabelVisible,
              })
            }}
          >
            {markerLabelVisible && (
              <InfoWindow
                onCloseClick={() => {
                  this.setState({
                    markerLabelVisible: !markerLabelVisible,
                  })
                }}
              >
                <Text>Hledané místo</Text>
              </InfoWindow>
            )}
          </Marker>
        )}

        {items.map(item => (
          <Marker
            position={item.location}
            onClick={e => this.handleMarkerClick(e, item.id)}
            icon={icons[item.type]}
          >
            {item.id === activeMarkerId && (
              <InfoWindow
                onCloseClick={() => {
                  this.setState({ activeMarkerId: null })
                }}
              >
                <Col alignItems="center">
                  <Text>{item.name}</Text>
                  {item.link && (
                    <>
                      <Gap gap="8px" />
                      <Text>
                        <Link asA target="_blank" href={item.link}>
                          Přejít na web
                        </Link>
                      </Text>
                    </>
                  )}
                </Col>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    )
  }
}

Map.defaultProps = {
  onFilteredItemsChange: () => {},
  onRefUpdate: () => {},
}

export default compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBYNaAhmMB388gVaj70mvJIRPi6vcu7jsE&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '655px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(Map)
